'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.incidents.factory:IncidentsManager

 # @description

###
angular
  .module 'mundoAdmin.incidents'
  .factory 'IncidentsManager',[
    'MundoDefaultManager'
    '$state'
    '$timeout'
    '$rootScope'
    'IncidentTypesManager'
    'IncidentPrioritiesManager'
    'DispatchGroupsManager'
    '$translate'
    'MundoMap'
    'UiHelpers'
    'ModelHelpers'
    'Restangular'
    (
      MundoDefaultManager
      $state
      $timeout
      $rootScope
      IncidentTypesManager
      IncidentPrioritiesManager
      DispatchGroupsManager
      $translate
      MundoMap
      UiHelpers
      ModelHelpers
      Restangular
    )->
      IncidentsManagerBase = new MundoDefaultManager()

      IncidentsManagerBase.setUrl('lpa/incidents')
      IncidentsManagerBase.setAutoTenant()
      IncidentsManagerBase.setNewObject([
        'locationLongitude',
        'locationLatitude',
        'locationDescription',
        'dispatchGroup',
        'reporterPhoneNumber',
        'reporterName',
        'externalId',
        'closedAt',
        'incidentPriority',
        'incidentType',
        # 'incidentSubtype'
      ])
      IncidentsManagerBase.setActions(['create', 'list', 'print', 'search', 'update'])
      IncidentsManagerBase.setSearchFields(['externalId', 'incidentType.label'])
      IncidentsManagerBase.editPermission = 'manage all MundoMosaLpaDispatchingBundle:Incident entities'
      IncidentsManagerBase.viewPermission = 'view all MundoMosaLpaDispatchingBundle:Incident entities'
      IncidentsManagerBase.defaultQuery.sort = 'externalId'
      IncidentsManagerBase.preloadIncidentTypesList = () ->
        IncidentsManagerBase.incidentTypesListPromise = IncidentTypesManager.getFullList({'sort':'label,ASC', 'filter[0]':'parentIncidentType.id,NOT_NULL'})
        IncidentsManagerBase.incidentTypesListPromise.then (types) =>
          angular.forEach types, (type) =>
            if type.parentIncidentType
              type.dynamicLabel = type.parentIncidentType.label+' - '+type.label
        IncidentsManagerBase.incidentTypesList = IncidentsManagerBase.incidentTypesListPromise.$object
      
      IncidentsManagerBase.getListSchema = ->
        [
          key: 'externalId'
          title: 'datatable.label.incidentId'
          sort: 'externalId'
        ,
          key: 'incidentType.label'
          title: 'datatable.label.incidentType'
          sort: 'incidentType.label'
        ,
        #   key: 'incidentSubtype.label'
        #   title: 'datatable.label.incidentSubtype'
        #   sort: 'incidentSubtype.label'
        # ,
          key: 'createdAt'
          title: 'datatable.label.created.at'
          sort: 'createdAt'
          type: 'dateTime'
        ,
          key: 'closedAt'
          title: 'datatable.label.closed.at'
          sort: 'closedAt'
          type: 'dateTime'
          hideInList: true
        ,
          key: 'locationDescription'
          title: 'Location description'
          hideInList: true
        ,
          key: 'coordinates'
          title: 'Location coordinates'
          hideInList: true
        ,
          key: 'reporterName'
          title: 'Reporter name'
          hideInList: true
        ,
          key: 'reporterPhoneNumber'
          title: 'Reporter phone number'
          hideInList: true
        ]

      IncidentsManagerBase.getNewForm = ->
        form = [
          key: 'externalId'
          type: 'input'
          name: 'externalId'
          templateOptions:
            label: $translate.instant('app.dispatch.incident')
            placeholder: $translate.instant('app.dispatch.incident')
            required: true
          controller: ($scope) ->
            $scope.to.loading = IncidentsManagerBase.getCount().then (result) ->
              index = parseInt(result) + 1
              index = index.toString().padStart(7, 0)
              prefix = ''
              prefix = $rootScope.user.activeUserContext.tenant.incidentPrefix if $rootScope.user.activeUserContext.tenant.incidentPrefix?
              $scope.model.externalId = prefix + index
          
        ,
          key: 'locationDescription'
          type: 'input'
          name: 'locationDescription'
          templateOptions:
            label: $translate.instant('app.dispatch.location')
            placeholder: $translate.instant('app.dispatch.location')
            required: true
        ,
          key: 'reporterPhoneNumber'
          type: 'input'
          name: 'reporterPhoneNumber'
          templateOptions:
            label: $translate.instant('app.dispatch.reporter-number')
            placeholder: $translate.instant('app.dispatch.reporter-number')
            required: true
        ,
          key: 'reporterName'
          type: 'input'
          name: 'reporterName'
          templateOptions:
            label: $translate.instant('app.dispatch.reporter-name')
            placeholder: $translate.instant('app.dispatch.reporter-name')
            required: true
        ,
          key: 'dispatchGroup'
          type: 'select'
          name: 'dispatchGroup'
          templateOptions:
            label: $translate.instant('app.dispatch.dispatchgroup')
            placeholder: $translate.instant('app.dispatch.dispatchgroup')
            required: true
            labelProp: 'label'
            valueProp: 'id'
            options: DispatchGroupsManager.getFullList({'sort' : 'label,ASC'}).$object
        ,
          key: 'incidentType'
          type: 'select'
          name: 'incidentType'
          templateOptions:
            label: $translate.instant('datatable.label.incidentType')
            placeholder: $translate.instant('datatable.label.incidentType')
            required: true
            labelProp: 'dynamicLabel'
            valueProp: 'id'
            options: IncidentsManagerBase.incidentTypesList
        ,
        #   key: 'incidentSubtype'
        #   type: 'select'
        #   name: 'incidentSubtype'
        #   templateOptions:
        #     label: $translate.instant('app.dispatch.incidentSubtype')
        #     placeholder: $translate.instant('app.dispatch.incidentSubtype')
        #     required: false
        #     labelProp: 'label'
        #     valueProp: 'id'
        #     options: []
        #   expressionProperties: {
        #     'templateOptions.disabled': '!model.incidentType || options.templateOptions.options.length < 1'
        #     'templateOptions.options': 'options.data.allOptions | incidentSubtypeFilter:model.incidentType'
        #   },
        #   data: {
        #     allOptions: incidentTypesList
        #   }
        # ,
          key: 'incidentPriority'
          type: 'select'
          name: 'incidentPriority'
          templateOptions:
            label: $translate.instant('app.dispatch.priority')
            placeholder: $translate.instant('app.dispatch.priority')
            required: true
            labelProp: 'label'
            valueProp: 'id'
            options: IncidentPrioritiesManager.getFullList({'sort' : 'label,ASC'}).$object
        ]
        form

      IncidentsManagerBase.getEditForm = (data) ->
        form = [
          key: 'externalId'
          type: 'input'
          name: 'externalId'
          defaultValue: data.externalId
          templateOptions:
            label: $translate.instant('app.dispatch.incident')
            placeholder: $translate.instant('app.dispatch.incident')
            required: true
            disabled: true
        ,
          key: 'locationDescription'
          type: 'input'
          name: 'locationDescription'
          defaultValue: data.locationDescription
          templateOptions:
            label: $translate.instant('app.dispatch.location')
            placeholder: $translate.instant('app.dispatch.location')
            required: true
        ,
          key: 'reporterPhoneNumber'
          type: 'input'
          name: 'reporterPhoneNumber'
          defaultValue: data.reporterPhoneNumber
          templateOptions:
            label: $translate.instant('app.dispatch.reporter-number')
            placeholder: $translate.instant('app.dispatch.reporter-number')
            required: true
        ,
          key: 'reporterName'
          type: 'input'
          name: 'reporterName'
          defaultValue: data.reporterName
          templateOptions:
            label: $translate.instant('app.dispatch.reporter-name')
            placeholder: $translate.instant('app.dispatch.reporter-name')
            required: true
        ,
          key: 'dispatchGroup'
          type: 'select'
          name: 'dispatchGroup'
          defaultValue: data.dispatchGroup.id
          templateOptions:
            label: $translate.instant('app.dispatch.dispatchgroup')
            placeholder: $translate.instant('app.dispatch.dispatchgroup')
            required: true
            labelProp: 'label'
            valueProp: 'id'
            options: DispatchGroupsManager.getFullList({'sort' : 'label,ASC'}).$object
        ,
          key: 'incidentType'
          type: 'select'
          name: 'incidentType'
          defaultValue: data.incidentType.id
          templateOptions:
            label: $translate.instant('datatable.label.incidentType')
            placeholder: $translate.instant('datatable.label.incidentType')
            required: true
            labelProp: 'dynamicLabel'
            valueProp: 'id'
            options: IncidentsManagerBase.incidentTypesList
        ,
        #   key: 'incidentSubtype'
        #   type: 'select'
        #   name: 'incidentSubtype'
        #   defaultValue: if data.incidentSubtype then data.incidentSubtype.id
        #   templateOptions:
        #     label: $translate.instant('app.dispatch.incidentSubtype')
        #     placeholder: $translate.instant('app.dispatch.incidentSubtype')
        #     required: false
        #     labelProp: 'label'
        #     valueProp: 'id'
        #     options: []
        #   expressionProperties: {
        #     'templateOptions.disabled': '!model.incidentType || options.templateOptions.options.length < 1'
        #     'templateOptions.options': 'options.data.allOptions | incidentSubtypeFilter:model.incidentType'
        #   },
        #   data: {
        #     allOptions: incidentTypesList
        #   }
        # ,
          key: 'incidentPriority'
          type: 'select'
          name: 'incidentPriority'
          defaultValue: data.incidentPriority.id
          templateOptions:
            label: $translate.instant('app.dispatch.priority')
            placeholder: $translate.instant('app.dispatch.priority')
            required: true
            labelProp: 'label'
            valueProp: 'id'
            options: IncidentPrioritiesManager.getFullList({'sort' : 'label,ASC'}).$object
        ]
        form

      IncidentsManagerBase.newIncidentFromCoordinates = (scope, coordinates) ->
        errors = []
        progress = false

        promise = new Promise (resolve, reject) ->
          geocoding = MundoMap.getLocationFromGeocoding coordinates[0], coordinates[1], $translate.use()
          geocoding.then (locationDescription) ->
            UiHelpers.openDialog 
              template: 'lpa/lpa-dispatching/views/incident-form.tpl.html'
              title: 'app.dispatch.addIncident'
              progress: progress
              errors: errors
              scope: scope
              data:
                new: true
                model: {}
                basic:
                  fields: IncidentsManagerBase.getNewFormWithLocationDescription(locationDescription)
              addIncident: (incident) ->
                @progress = true
                modal = @
                check = modal.data.basic.form.locationDescription.$dirty
              
                ModelHelpers.verifyLocation check, incident, coordinates
                .then (incident) ->
                  IncidentsManagerBase.newIntercadIncident incident
                  .then (result) ->
                    modal.progress = false
                    resolve(result)
                  , (error) ->
                    modal.progress = false
                    for key, value of error.data.errors.children
                      if value.errors?
                        modal.errors.push
                          key: key
                          value: value.errors

      IncidentsManagerBase.editIncident = (incident) ->
        errors = []
        progress = false

        oldCoordinates = [
          incident.locationLongitude,
          incident.locationLatitude
        ]
        incidentId = incident.id

        promise = new Promise (resolve, reject) ->
          UiHelpers.openDialog 
            template: 'lpa/lpa-dispatching/views/incident-form.tpl.html'
            title: 'app.dispatch.editIncident'
            progress: progress
            errors: errors
            data:
              new: false
              model: {}
              basic:
                fields: IncidentsManagerBase.getEditForm(incident)
            updateIncident: (incident) ->
              @progress = true
              modal = @
              check = modal.data.basic.form.locationDescription.$dirty
            
              ModelHelpers.verifyLocation check, incident, oldCoordinates
              .then (incident) ->
                IncidentsManagerBase.updateIntercadIncident incident, incidentId
                .then (result) ->
                  modal.progress = false
                  resolve(result)
                , (error) ->
                  modal.progress = false
                  for key, value of error.data.errors.children
                    if value.errors?
                      modal.errors.push
                        key: key
                          value: value.errors

      IncidentsManagerBase.newIntercadIncident = (incident) ->
        new Promise (resolve, reject) ->
          IncidentsManagerBase.setUrl("lpa/intercad/incidents")
          IncidentsManagerBase.new incident
          .then (result) ->
            resolve(result)
          , (error) ->
            reject(error)

      IncidentsManagerBase.updateIntercadIncident = (incident, incidentId) ->
        new Promise (resolve, reject) ->
          IncidentsManagerBase.setUrl("lpa/intercad/incidents")
          IncidentsManagerBase.update incidentId, incident
          .then (result) ->
            resolve(result)
          , (error) ->
            reject(error)

      IncidentsManagerBase.closeIntercadIncident = (incident) ->
        new Promise (resolve, reject) ->
          incident.closedAt = moment().format()
          IncidentsManagerBase.setUrl("lpa/intercad/incidents")
          IncidentsManagerBase.delete incident.id, incident
          .then (result) ->
            resolve(result)
          , (error) ->
            reject(error)

      IncidentsManagerBase.closeIncident = (incident) ->
        new Promise (resolve, reject) ->
          IncidentsManagerBase.setUrl("lpa/intercad/incidents")
          IncidentsManagerBase.delete incident.id
          .then (result) ->
            resolve(result)
          , (error) ->
            reject(error)

      IncidentsManagerBase.getCount = () ->
        new Promise (resolve, reject) ->
          Restangular.all("lpa/incidents/count").one($rootScope.user.activeUserContext.tenant.id).get()
          .then (result) ->
            resolve(result)
          , (error) ->
            reject(error)

      IncidentsManagerBase.getNewFormWithLocationDescription = (locationDescription) ->
        form = IncidentsManagerBase.getNewForm()
        index = _.indexOf form, _.findWhere(form, { key: "locationDescription" })
        form[index].defaultValue = locationDescription
        form

      IncidentsManagerBase.getDetailTemplateUrl = ->
        'mundo-admin/incidents/views/incident-detail.tpl.html'

      IncidentsManagerBase.valuePostProcessor = (path, value, row) ->
        if path == 'coordinates'
          lat = row.locationLatitude
          lon = row.locationLongitude
          return "#{lat} , #{lon}"

        return value

      IncidentsManagerBase.getSchemaLinks = ->
        [
          key: 'label'
          title: 'Tasks'
          multiple: 'tasks'
          hideInList: true
          action: (part) ->
            contactlabel = part.label
            $state.go('tasks.tasks').then(->
              $timeout(->
                $rootScope.$broadcast('searchFor',
                  {term: contactlabel, to: 'app.admin.pageTitles.tasks.tasks'})
              ,100)
            )
        ]

      unselectedIcon = 'add_circle_outline'
      unselectedText = 'show all incidents'

      selectedIcon = 'remove_circle_outline'
      selectedText = 'show only active incidents'

      filterOnClosed =
        [
          icon: unselectedIcon
          text: unselectedText
          action: (ctrl) ->
            if @.icon == selectedIcon
              @.icon = unselectedIcon
              @.text = unselectedText
            else
              @.icon = selectedIcon
              @.text = selectedText

            show = IncidentsManagerBase.filterClosed()
            # reload the items in the list
            loadFilterParams = $state.$current.locals['incidents@incidents'].$scope.listCtrl.loadFilterParams
            if show
              loadFilterParams['filter[closedAt]'] = undefined
            else
              loadFilterParams['filter[closedAt]'] = 'OR,closedAt,NULL,closedAt,GTE ' + moment.utc().format()

            $state.$current.locals['incidents@incidents'].$scope.listCtrl.loadFilterParams = loadFilterParams
            $state.$current.locals['incidents@incidents'].$scope.listCtrl.schema[3].hideInList = !show
            $state.$current.locals['incidents@incidents'].$scope.listCtrl.load()
        ]

      IncidentsManagerBase.filterClosed = () =>
        @showClosed = !@showClosed
        @showClosed

      IncidentsManagerBase.addTableAction(filterOnClosed)

      IncidentsManagerBase.showClosed = false

      IncidentsManagerBase
  ]
